import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import globalReducer from "state"
import { Provider } from 'react-redux';
import {api} from "state/api.js"
import { setupListeners } from '@reduxjs/toolkit/query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//configuring the redux setup 
const store=configureStore({
  reducer:{
    // auth: authReducer,
    global:globalReducer,
    [api.reducerPath]:api.reducer,
  },
  middleware:(getDefault)=>getDefault().concat(api.middleware),
})
setupListeners(store.dispatch);


//initial start up of react app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <App/>
      <ToastContainer />
    </Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
