import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useGetCategoryQuery } from 'state/api';
import Header from 'components/Header';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 1,
    renderCell: (params) => {
      return new Date(params.value).toLocaleString();
    }
  },
  
];

const CategoryTypes = () => {
  const { data, isLoading } = useGetCategoryQuery();
  const theme = useTheme();

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Category Type" subtitle="List of category types" />
      <Box mt="40px" height="100vh"
       sx={{
        "& .MuiDataGrid-root": {
          border: "none"
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100], // Add bottom border
          fontWeight: 'bold', // Make header text bold
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          borderRight: "1px solid rgba(224, 224, 224, 1) !important", // Add right border to header and cells
          borderLeft: "1px solid rgba(224, 224, 224, 1) !important", // Add left border to header and cells
           // Add bottom border to cells
        },
        "& .MuiDataGrid-columnHeader:first-of-type, .MuiDataGrid-cell:first-of-type": {
          borderLeft: "none !important" // Remove left border for first column
        },
        "& .MuiDataGrid-columnHeader:last-of-type, .MuiDataGrid-cell:last-of-type": {
          borderRight: "none !important" // Remove right border for last column
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.primary.light
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: "none",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${theme.palette.secondary[200]} !important`,
        },
      }}
      >
        <DataGrid 
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          columns={columns}
          rows={data || []}
        />
      </Box>
    </Box>
  );
}

export default CategoryTypes;
